// src/components/Header.js

import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
  useTheme,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import lightLogo from '../img/essentivit-logo.svg'; // SVG light mode logo
import darkLogo from '../img/essentivit-logo-darkmode.svg'; // SVG dark mode logo
import { useAuth0 } from '@auth0/auth0-react';

function Header({ onLogoClick, toggleTheme, isDarkMode, setActiveTab }) {
  const theme = useTheme();
  const { logout } = useAuth0();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const preloadImage = (src) => {
      const img = new Image();
      img.src = src;
    };

    preloadImage(lightLogo);
    preloadImage(darkLogo);
  }, []);

  const logo = theme.palette.mode === 'dark' ? darkLogo : lightLogo;

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const navItems = [
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Records', value: 'records' },
    { label: 'Lifetime', value: 'lifetime' },
    { label: 'SysInfo', value: 'sysinfo' },
  ];

  const handleMenuItemClick = (value) => {
    setActiveTab(value); // Set the active tab
    setDrawerOpen(false); // Close the Drawer
  };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          {/* Logo */}
          <img
            src={logo}
            alt="essentiVit Logo"
            style={{
              height: isMobile ? 40 : 50,
              marginRight: isMobile ? 8 : 16,
              cursor: 'pointer',
            }}
            onClick={onLogoClick}
          />
          {/* Title */}
          {!isMobile && (
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              Sales Dashboard
            </Typography>
          )}
          {/* Navigation and Actions */}
          {isMobile ? (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerToggle}
              >
                <Box
                  sx={{ width: 250 }}
                  role="presentation"
                  // Prevent closing Drawer when clicking inside the menu items
                  onClick={(event) => {
                    if (event.target.closest('li')) {
                      event.stopPropagation();
                    }
                  }}
                  onKeyDown={handleDrawerToggle}
                >
                  <List>
                    {navItems.map((item) => (
                      <ListItem
                        button
                        key={item.value}
                        onClick={() => handleMenuItemClick(item.value)}
                      >
                        <ListItemText primary={item.label} />
                      </ListItem>
                    ))}
                    {/* Action Buttons */}
                    <ListItem button onClick={toggleTheme}>
                      <ListItemText
                        primary={isDarkMode ? 'Light Mode' : 'Dark Mode'}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() =>
                        logout({ returnTo: window.location.origin })
                      }
                    >
                      <ListItemText primary="Logout" />
                    </ListItem>
                  </List>
                </Box>
              </Drawer>
            </>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {/* Dark mode toggle button */}
              <Button
                onClick={toggleTheme}
                variant="contained"
                color="primary"
                sx={{
                  padding: '6px 16px',
                  fontSize: '0.875rem',
                }}
              >
                {isDarkMode ? 'Light' : 'Dark'} Mode
              </Button>
              {/* Logout Button */}
              <Button
                onClick={() => logout({ returnTo: window.location.origin })}
                variant="outlined"
                color="secondary"
                sx={{
                  padding: '6px 16px',
                  fontSize: '0.875rem',
                }}
              >
                Logout
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;

// src/components/DashboardTabs.js

import React from 'react';
import DailyTab from './DailyTab';
import SysInfoTab from './SysInfoTab';
import WeeklyTab from './WeeklyTab';
import MonthlyTab from './MonthlyTab';
import RecordsTab from './RecordsTab';
import LifetimeTab from './LifetimeTab';
import StockTab from './StockTab'; // Import the new StockTab component

function DashboardTabs({ activeTab, apiData }) {
  const renderTabContent = () => {
    switch (activeTab) {
      case 'daily':
        return <DailyTab apiData={apiData} />;
      case 'weekly':
        return <WeeklyTab apiData={apiData} />;
      case 'monthly':
        return <MonthlyTab apiData={apiData} />;
      case 'records':
        return <RecordsTab apiData={apiData} />;
      case 'lifetime':
        return <LifetimeTab apiData={apiData} />;
      case 'stock': // New case for the Stock tab
        return <StockTab apiData={apiData} />;
      case 'sysinfo':
        return <SysInfoTab apiData={apiData} />;
      default:
        return <DailyTab apiData={apiData} />;
    }
  };

  return <div className="tab-content">{renderTabContent()}</div>;
}

export default DashboardTabs;

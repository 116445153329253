import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';

// Create a wrapper to include useNavigate inside Auth0Provider
const Auth0WithRouter = () => {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin + '/login', // Redirect to /login to handle errors
      }}
      onRedirectCallback={(appState) => {
        navigate(appState?.returnTo || '/');
      }}
    >
      <App />
    </Auth0Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0WithRouter />
    </BrowserRouter>
  </React.StrictMode>
);

// src/components/DailyTab.js

import React from 'react';
import { Grid, Container, Typography } from '@mui/material';
import Card from './Card';
import cardConfig from '../cardConfig';
import { useTheme } from '@mui/material/styles';

function DailyTab({ apiData }) {
  const theme = useTheme();

  if (!apiData) {
    return (
      <Container maxWidth="lg" sx={{ padding: '20px' }}>
        <Typography variant="h6">Loading...</Typography>
      </Container>
    );
  }

  // Separate cards by type
  const dateCard = cardConfig.daily.find(card => card.type === 'date');
  const totalCards = cardConfig.daily.filter(card => card.type === 'total');
  const marketplaceCards = cardConfig.daily.filter(card => card.type === 'marketplace');
  const stockAlertCards = cardConfig.daily.filter(card => card.type === 'stockAlert');

  // Function to render a regular card
  const renderRegularCard = (card, key, gridProps = {}) => {
    const rawValue = apiData[card.valueKey];
    const value = rawValue !== undefined ? rawValue : 'N/A';
    const formattedValue = card.formatValue ? card.formatValue(value) : value;

    return (
      <Grid item key={key} {...gridProps}>
        <Card
          title={card.title}
          value={formattedValue}
          color={card.color}
          icon={card.icon}
        />
      </Grid>
    );
  };

  // Function to render marketplace-specific cards
  const renderMarketplaceCards = () => {
    return marketplaceCards.map((card, index) => {
      const key = `marketplace-card-${index}`;
      const rawValue = apiData[card.valueKey];
      const value = rawValue !== undefined ? rawValue : 'N/A';
      const formattedValue = card.formatValue ? card.formatValue(value) : value;

      return (
        <Grid item key={key} xs={12} sm={6} md={3}>
          <Card
            title={card.title}
            value={formattedValue}
            color={card.color}
            icon={card.icon}
          />
        </Grid>
      );
    });
  };

  // Function to render stock alert cards
  const renderStockAlertCards = () => {
    return stockAlertCards.map((card, index) => {
      const key = `stock-alert-card-${index}`;

      const rawProductNames = apiData[card.valueKey];
      const rawDaysOOS = apiData[card.daysOutKey];

      // Modified condition
      if (
        !rawProductNames ||
        rawProductNames.trim() === '' ||
        rawProductNames.trim().toLowerCase() === 'none'
      ) {
        return null; // Do not render the card if there is no product name
      }

      // Split the comma-separated strings into arrays
      const productNames = rawProductNames.split(',').map(item => item.trim());
      const daysOOS = rawDaysOOS
        ? rawDaysOOS.split(',').map(item => item.trim())
        : [];

      // Build an array of products with their days out of stock
      const products = productNames.map((name, idx) => ({
        name,
        daysOOS: daysOOS[idx] || 'N/A',
      }));

      // Prepare the content to display
      const content = products.map(product => (
        <Typography key={product.name} variant="body2">
          <strong>{product.name}</strong> - {product.daysOOS} days out of stock
        </Typography>
      ));

      return (
        <Grid item key={key} xs={12}>
          <Card
            title={card.title}
            color={card.color}
            icon={card.icon}
            type="stockAlert"
          >
            {content}
          </Card>
        </Grid>
      );
    });
  };

  // Check if there is any stock alert data to display
  const hasStockAlerts = stockAlertCards.some(card => {
    const rawProductNames = apiData[card.valueKey];
    return (
      rawProductNames &&
      rawProductNames.trim() !== '' &&
      rawProductNames.trim().toLowerCase() !== 'none'
    );
  });

  return (
    <Container maxWidth="lg" sx={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}>
      <Grid container spacing={4} justifyContent="center">
        {/* Date Card: Full Width */}
        {dateCard && renderRegularCard(dateCard, 'date-card', { xs: 12 })}

        {/* Total Units and Total Revenue: Half Width Each */}
        {totalCards.map((card, index) =>
          renderRegularCard(card, `total-card-${index}`, { xs: 12, sm: 6 })
        )}

        {/* Marketplace-Specific Cards */}
        {renderMarketplaceCards()}

        {/* Stock Alerts Section */}
        {hasStockAlerts && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Stock Alerts
              </Typography>
            </Grid>
            {renderStockAlertCards()}
          </>
        )}
      </Grid>
    </Container>
  );
}

export default DailyTab;

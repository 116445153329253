// src/components/StockTab.js

import React from 'react';
import { Grid, Container, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function StockTab({ apiData }) {
  const theme = useTheme();

  if (!apiData) {
    return (
      <Container maxWidth="lg" sx={{ padding: '20px' }}>
        <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
          Loading...
        </Typography>
      </Container>
    );
  }

  // Extract the data for Non UK Stock and UK Only Stock
  const nonUKStockData = apiData.nonUKStock || [];
  const stockAlertProductOOS = apiData.stock_alert_product_oos || '';
  const stockAlertDaysOOS = apiData.stock_alert_days_oos || '';

  // Process UK Only Stock data
  const ukOnlyStockProducts = stockAlertProductOOS
    .split(',')
    .map((item) => item.trim())
    .filter((item) => item && item.toLowerCase() !== 'none');
  const ukOnlyStockDays = stockAlertDaysOOS
    .split(',')
    .map((item) => item.trim());

  // Combine products and days out of stock
  const ukOnlyStockData = ukOnlyStockProducts.map((name, index) => ({
    name,
    daysOOS: ukOnlyStockDays[index] || 'N/A',
  }));

  return (
    <Container
      maxWidth="lg"
      sx={{ paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px' }}
    >
      <Grid container spacing={4}>
        {/* Non UK Stock Section */}
        <Grid item xs={12}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: theme.palette.text.primary }}
          >
            Non UK Stock
          </Typography>
          <Box sx={{ maxHeight: '400px', overflow: 'auto' }}>
            <Grid container spacing={2}>
              {/* Table Headers */}
              <Grid item xs={3}>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  sx={{ color: theme.palette.text.primary }}
                >
                  SKU
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  sx={{ color: theme.palette.text.primary }}
                >
                  Product Name
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  sx={{ color: theme.palette.text.primary }}
                >
                  Quantity
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  sx={{ color: theme.palette.text.primary }}
                >
                  Real-Time Stock
                </Typography>
              </Grid>

              {/* Non UK Stock Data */}
              {nonUKStockData.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={3}>
                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                      {item.sku}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                      {item.product_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                      {item.quantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                      {item.realtime_stock}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Box>
        </Grid>

        {/* UK Only Stock Section */}
        <Grid item xs={12} sx={{ marginTop: '40px' }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: theme.palette.text.primary }}
          >
            UK Only Stock (Out of Stock Alerts)
          </Typography>
          {ukOnlyStockData.length > 0 ? (
            <Box sx={{ maxHeight: '400px', overflow: 'auto' }}>
              <Grid container spacing={2}>
                {/* Table Headers */}
                <Grid item xs={8}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ color: theme.palette.text.primary }}
                  >
                    Product Name
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{ color: theme.palette.text.primary }}
                  >
                    Days Out of Stock
                  </Typography>
                </Grid>

                {/* UK Only Stock Data */}
                {ukOnlyStockData.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={8}>
                      <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {item.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant="body2"
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {item.daysOOS}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Box>
          ) : (
            <Typography
              variant="body2"
              sx={{ color: theme.palette.text.secondary }}
            >
              All products are in stock.
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default StockTab;

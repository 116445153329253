// src/components/NavBar.js

import React from 'react';
import { Tabs, Tab, Container } from '@mui/material';

function NavBar({ activeTab, setActiveTab }) {
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ marginBottom: '20px' }}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          borderBottom: '2px solid #e0e0e0',
          justifyContent: 'flex-start',
        }}
      >
        <Tab label="Daily" value="daily" />
        <Tab label="Weekly" value="weekly" />
        <Tab label="Monthly" value="monthly" />
        <Tab label="Records" value="records" />
        <Tab label="Lifetime" value="lifetime" />
        <Tab label="Stock" value="stock" /> {/* Added new Stock tab */}
        <Tab label="SysInfo" value="sysinfo" />
      </Tabs>
    </Container>
  );
}

export default NavBar;
